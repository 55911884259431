import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.services-grid-block__carousel');
  const breakpoint = window.matchMedia('(min-width:768px)');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      let swiper;

      const breakpointChecker = function () {
        if (breakpoint.matches === true) {
          if (swiper !== undefined) swiper.destroy(true, true);
        } else if (breakpoint.matches === false) {
          return enableSwiper();
        }
      };

      const enableSwiper = function () {
        swiper = new Swiper(carousel, {
          loop: false,
          slidesPerView: 1,
          a11y: true,
          keyboardControl: true,
          grabCursor: true,

          navigation: {
            prevEl: carousel.querySelector('.swiper-button-prev'),
            nextEl: carousel.querySelector('.swiper-button-next'),
          },

          breakpoints: {
            580: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
          },
        });
      };

      breakpoint.addListener(breakpointChecker);
      breakpointChecker();
    });
  }
});
