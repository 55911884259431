import $ from 'jquery';

let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      const instructionText = fileUpload.querySelector(
        '.gform_drop_instructions'
      );
      const icon = document.createElement('i');
      icon.classList.add('icon-importer');
      instructionText.textContent = wp.i18n._x(
        'Drag and drop file to upload',
        'drag and drop instruction',
        'vtx'
      );
      instructionText.append(icon);
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function addFormEvents() {
  const fields = document.querySelectorAll(
    '.gfield:not(.gfield--radio):not(.gfield--select)'
  );

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      field.classList.add('initialized');

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

$(document).on('gform_post_render', function () {
  fieldsAreInitialized = true;
  addFormEvents();
  changeFileUpload();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    addFormEvents();
    changeFileUpload();
  }
});
