/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/progress-bar');
require('./main/tom-selects');
require('./main/popup-form');
require('./main/service-grid');
require('./main/style-btns');
require('./main/news-carousel');
