const progressBar = document.querySelector('#progress-bar');

if (progressBar) {
  document.addEventListener('scroll', () => {
    const docElem = document.documentElement;
    const docBody = document.body;
    const scrollTop = docBody.scrollTop || docElem.scrollTop;
    const height = docElem.scrollHeight - docElem.clientHeight;
    const readPercent = (scrollTop / height) * 100;

    if (readPercent > 0) {
      progressBar.style.setProperty('--progress', readPercent + '%');
    } else {
      progressBar.style.setProperty('--progress', '0%');
    }
  });
}
