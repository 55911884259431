import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const selects = document.querySelectorAll(
    'select.custom-select, .gform_wrapper select'
  );

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select);
    });
  }
});
