/* load more buttons */
document.addEventListener('facetwp-loaded', function () {
  const btns = document.querySelectorAll('.facetwp-load-more');

  if (0 < btns.length) {
    btns.forEach((btn) => {
      console.log(btn);
      const span = document.createElement('span');
      span.classList.add('extra-markup');
      console.log(span);
      btn.appendChild(span);
    });
  }
});
