import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const newsBlocks = document.querySelectorAll('.news-carousel-block');

  if (0 < newsBlocks.length) {
    newsBlocks.forEach((block) => {
      new Swiper(block.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 20,

        breakpoints: {
          580: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.4,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3.55,
            spaceBetween: 60,
          },
        },

        navigation: {
          prevEl: block.querySelector('.swiper-button-prev'),
          nextEl: block.querySelector('.swiper-button-next'),
        },
      });
    });
  }
});
